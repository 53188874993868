import { CustomerConfigurations } from "views/admin/customers/components/ConfigurationCustomers";

export interface CustomerResponse {
  id: string;
  name: string;
  balance: string;
  balancePending: string;
  amountLocked: string;
  features: {
    API: boolean;
    PIX: boolean;
    CREDIT_CARD: boolean;
    BILLET: boolean;
    INVOICE_API: boolean;
    PIX_API: boolean;
    WITHDRAW_API: boolean;
    CREDIT_CARD_API: boolean;
    MERCADO_PAGO_API: boolean;
    BILLET_API: boolean;
    CONFIGURE_AUTOMATIC_WITHDRAW: boolean;
  };
  CustomersSecurity: {
    enabled: boolean;
  };
  plan: {
    name: string;
    id: number;
  };
  Company: {
    id: string;
    name: string;
  };
  status: number;
  email: string;
  CustomerResponsible: any[];
  document_number: string;
  Movements: Movement[];
  CustomerAccounts: CustomerAccount[];
  MainCustomer: any;
  CustomerTaxes: CustomerTax[];
  CustomerAddress: CustomerAddress[];
  CustomerContact: CustomerContact[];
  configurations: CustomerConfigurations;
  CustomerAutomaticWithdrawel: {
    id: string;
    active: boolean;
    maxAutomaticWithdrawal: string;
    percent: string;
    type: "DAILY" | "WEEKLY" | "MONTHLY" | "HOURLY" | "MINUTLY";
    period: number[];
    customerAccountId: string;
  };
}

export interface CustomerAddress {
  id: number;
  customer_id: string;
  address: string;
  number_address: string;
  neigherhood: string;
  complement: string;
  cepId: number;
  default_address: boolean;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: any;
  Cep: {
    id: number;
    cep: string;
  };
}

export interface CustomerContact {
  id: number;
  customerId: string;
  contact: string;
  defaultContact: boolean;
  validated: boolean;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: any;
}

export interface Movement {
  id: string;
  amount: string;
  orderAt: string;
  taxes: string;
  amountLiquid: string;
  antecipationTax: string;
  maquinetaId: string;
}

export interface CustomerAccount {
  account?: string;
  id: string;
  agency?: string;
  bankIspb?: string;
  pix: string;
  createdAt: string;
}

export interface CustomerTax {
  id: string;
  customerId: string;
  taxId: any;
  type: string;
  maxFixed: string;
  origin: string;
  amount: string;
}

export interface BalancesPending {
  creditCard: string;
  debitCard: string;
  pix: string;
  lock: string;
  withdraw: string;
}

export enum FreezeMethods {
  CREDIT_CARD = "creditCard",
  DEBIT_CARD = "debitCard",
  PIX = "pix",
  WITHDRAW = "withdraw",
  LOCK = "lock",
}
