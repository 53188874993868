import { CopyIcon } from "@chakra-ui/icons";
import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import FormRemoteSelectInput, {
  SelectOptionType,
} from "components/FormRemoteSelectInput";
import InputForm from "components/InputForm";
import useAuth, { UserPermissions } from "contexts/useAuth";
import { queryClient } from "index";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { copyPlan, findAllCompanys, findPlan } from "services/api.service";
export interface CopyPlanForm {
  description: string;
  name: string;
  company?: SelectOptionType;
}

const CopyPlan: React.FC<{ id: number }> = ({ id }) => {
  const {
    hasPermission,
    userData: { isCustomerAdmin },
  } = useAuth();
  const hasPermCreateDuplicate = hasPermission(UserPermissions.PLAN_WRITE);

  const [isLoading, setIsLoading] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { control, handleSubmit, setValue } = useForm<CopyPlanForm>({
    defaultValues: {},
  });

  const { data: plan } = useQuery(["findPlan", id], () => findPlan(id), {
    enabled: !!id && isOpen,
  });

  const toast = useToast();

  const onSubmit = (data: CopyPlanForm) => {
    setIsLoading(true);
    copyPlan({
      ...data,
      companyId: data.company?.value,
      copyId: plan.id,
    })
      .then(() => {
        queryClient.refetchQueries(["findAllPlans"]);
        toast({
          title: "Sucesso",
          description: "Plano copiado com sucesso",
          status: "success",
          duration: 5000,
          position: "top",
          isClosable: true,
        });
        onClose();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (plan) {
      setValue("name", plan.name);
      setValue("description", plan.description);
      setValue("company", {
        label: plan.company?.name,
        value: plan.companyId,
      });
    }
  }, [plan, setValue]);

  return (
    <>
      {hasPermCreateDuplicate && (
        <Tooltip label={"Duplicar plano"} aria-label="Visualizar">
          <IconButton
            aria-label="Copiar plano"
            rounded="md"
            size="sm"
            variant="outline"
            icon={<CopyIcon />}
            onClick={() => {
              onOpen();
            }}
          />
        </Tooltip>
      )}
      {isOpen && plan && (
        <>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent minW={{ lg: "1200px", md: "700px", sm: "400px" }}>
              <ModalHeader>{"Copiar "} Plano</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <VStack spacing={3}>
                    {!isCustomerAdmin && (
                      <FormRemoteSelectInput
                        control={control}
                        name="company"
                        label="Empresa (Opcional)"
                        loadDataFn={({ value, cb }) =>
                          findAllCompanys({
                            filter: value,
                            limit: 10,
                            page: 0,
                          }).then((retorno) => {
                            if (retorno?.registers?.length > 0) {
                              cb([
                                { label: "Escolher uma opção...", value: "" },
                                ...retorno.registers?.map((d: any) => ({
                                  label: d.name,
                                  value: d.id,
                                })),
                              ]);
                            } else {
                              cb([]);
                            }
                          })
                        }
                      />
                    )}
                    <InputForm
                      control={control}
                      name="name"
                      type="text"
                      label="Nome"
                      rules={{ required: "Campo obrigatório" }}
                    />
                    <InputForm
                      control={control}
                      name="description"
                      type="text"
                      label="Descrição"
                      rules={{ required: "Campo obrigatório" }}
                    />
                  </VStack>
                  <Text my={5} fontSize={18} fontWeight="bold">
                    Configuração das Taxas se mantém
                  </Text>

                  <VStack>
                    <Button
                      mt={5}
                      type="submit"
                      variant={"outline"}
                      isLoading={isLoading}
                      w={`100%`}
                      color={"green"}
                    >
                      Criar
                    </Button>
                  </VStack>
                </form>
              </ModalBody>

              <ModalFooter></ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
};

export default CopyPlan;
