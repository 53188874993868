import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import FormRemoteSelectInput, {
  SelectOptionType,
} from "components/FormRemoteSelectInput";
import InputNumber from "components/InputNumber";
import MultiSelectForm from "components/MultiSelectForm";
import PercentageNumberInput from "components/PercentageInput";
import SelectForm from "components/SelectForm";
import { useTwoFaHelper } from "contexts/TwoFaCheckContext";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaSortAmountDown } from "react-icons/fa";
import { automaticWithdraw, getAccounts } from "services/api.service";

type AutomaticType = "DAILY" | "WEEKLY" | "MONTHLY" | "HOURLY" | "MINUTLY";

export interface CustomerConfigurations {
  percent: number;
  accountId: SelectOptionType;
  maxAutomaticWithdrawal: string;
  type: AutomaticType;
  periodMinutly?: string;
}

const weeklyMap = {
  0: "Domingo",
  1: "Segunda",
  2: "Terça",
  3: "Quarta",
  4: "Quinta",
  5: "Sexta",
  6: "Sábado",
};

const AutomaticWithdrawModal: React.FC<{
  customerId: string;
  config?: CustomerConfigurations & { period: number[] };
  refetch: () => {};
}> = ({ customerId, config, refetch }) => {
  const toast = useToast();
  const { requestTwoFa } = useTwoFaHelper();
  const [loading, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [type, setType] = React.useState<AutomaticType>("MINUTLY");
  const { handleSubmit, control, reset, setValue } = useForm<
    CustomerConfigurations & { period: { label: string; value: number }[] }
  >();
  const save = async ({
    maxAutomaticWithdrawal,
    accountId,
    percent,
    period,
    periodMinutly,
    type,
  }: CustomerConfigurations & {
    period: { label: string; value: number }[];
  }) => {
    const twofaData = await requestTwoFa();
    if (!twofaData?.id) {
      setLoading(false);
      toast({
        title: "Erro",
        description: "Autenticação não informada",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);
    automaticWithdraw(customerId, twofaData.id, {
      maxAutomaticWithdrawal: parseFloat(maxAutomaticWithdrawal),
      accountId: accountId.value,
      percent: percent / 100,
      active: true,
      period:
        type === "MINUTLY"
          ? [+periodMinutly]
          : period.map((item) => +item.value),
      type,
    })
      .then(() => {
        onClose();
        refetch();
        toast({
          title: "Sucesso",
          description: "Cliente atualizado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao atualizar cliente",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (config && isOpen) {
      setValue("percent", config.percent);
      setValue("maxAutomaticWithdrawal", config.maxAutomaticWithdrawal);
      setValue(
        "period",
        config.period?.map((item) => {
          return {
            label: config.type === "WEEKLY" ? weeklyMap[item] : String(item),
            value: item,
          };
        }) ?? [
          {
            label: "1 minuto",
            value: 1,
          },
        ]
      );

      if (config.type === "MINUTLY") {
        setValue("periodMinutly", config.periodMinutly);
      }
      setValue("type", config.type || "MINUTLY");
      setValue("accountId", config.accountId);
      setType(config.type || "MINUTLY");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, config, setValue]);

  return (
    <>
      <Button
        onClick={() => {
          onOpen();
          reset();
        }}
        size="sm"
        rounded="sm"
        variant="outline"
        colorScheme="brand"
      >
        <FaSortAmountDown />
        <Text ml={2}>Saques Recorrentes</Text>
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Editar Atributos</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={3}>
                <InputNumber
                  label="Valor máximo de saque recorrente"
                  control={control}
                  name="maxAutomaticWithdrawal"
                  rules={{ required: true }}
                />
                <PercentageNumberInput
                  label="Percentual de saque recorrente"
                  control={control}
                  name="percent"
                  rules={{ required: true }}
                />
                <SelectForm
                  control={control}
                  name="type"
                  onChange={(e) => {
                    setType(e as AutomaticType);
                    setValue("period", []);
                  }}
                  label="Tipo de transferência"
                  options={[
                    { label: "Minuto", value: "MINUTLY" },
                    { label: "Diário", value: "DAILY" },
                    { label: "Semanal", value: "WEEKLY" },
                    { label: "Mensal", value: "MONTHLY" },
                    { label: "Por hora", value: "HOURLY" },
                  ]}
                />
                {type === "MINUTLY" && (
                  <SelectForm
                    control={control}
                    name="periodMinutly"
                    label="Minuto"
                    options={[
                      {
                        label: "1 minuto",
                        value: "1",
                      },
                      {
                        label: "5 minutos",
                        value: "5",
                      },
                      {
                        label: "10 minutos",
                        value: "10",
                      },
                      {
                        label: "15 minutos",
                        value: "15",
                      },
                      {
                        label: "30 minutos",
                        value: "30",
                      },
                    ]}
                  />
                )}
                {type === "WEEKLY" && (
                  <MultiSelectForm
                    control={control}
                    name="period"
                    label="Dia da semana"
                    options={[
                      { label: "Domingo", value: "0" },
                      { label: "Segunda", value: "1" },
                      { label: "Terça", value: "2" },
                      { label: "Quarta", value: "3" },
                      { label: "Quinta", value: "4" },
                      { label: "Sexta", value: "5" },
                      { label: "Sábado", value: "6" },
                    ]}
                  />
                )}
                {type === "DAILY" && (
                  <MultiSelectForm
                    control={control}
                    name="period"
                    label="Dia da semana"
                    options={[...Array(30).keys()].map((item) => ({
                      label: (item + 1).toString(),
                      value: (item + 1).toString(),
                    }))}
                  />
                )}
                <FormRemoteSelectInput
                  control={control}
                  label="Conta cadastrada?"
                  loadDataFn={({ value, cb }) =>
                    getAccounts({
                      filter: value,
                      customerId: customerId,
                    }).then((retorno) => {
                      if (retorno?.registers?.length > 0) {
                        cb([
                          {
                            label: "Escolha uma conta",
                            value: "",
                          },
                          ...retorno.registers?.map((d: any) => ({
                            label: d.pix || d.agency + " - " + d.account,
                            value: d.id,
                          })),
                        ]);
                      } else {
                        cb([]);
                      }
                    })
                  }
                  name="accountId"
                />
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(save)}
                >
                  Salvar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default AutomaticWithdrawModal;
